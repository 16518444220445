
import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateInputComponent),
        multi: true
    }]
})
export class DateInputComponent implements ControlValueAccessor {
    private mOnChange: (value: any) => void = () => {};
    private mOnTouched: () => void = () => {};
    
    day: number | null = null;
    month: number | null = null;
    year: number | null = null;

    dayInputHandler(e: any) {
        let newValue = parseInt(e.target.value)
        if (isNaN(newValue) || newValue > 31 || newValue < 1) {
            this.day = null
            return
        }
        this.day = newValue;
        if (this.month) {
            this.monthNumberOfDaysCheck();
        }
        this.emitValueIfValid();
        this.mOnTouched();
    }

    monthInputHandler(e: any) {
        const newValue = parseInt(e.target.value)
        if (isNaN(newValue) || newValue > 12 || newValue < 1) {
            this.month = null
            return
        }
        this.month = newValue
        this.emitValueIfValid();
        this.mOnTouched();
    }

    monthChangeHandler(e: any) {
        const newValue = parseInt(e.target.value)
        this.monthNumberOfDaysCheck();
        this.emitValueIfValid();
        this.mOnTouched();
    }

    monthNumberOfDaysCheck() {
        if (this.day != null) {
            if (this.month == 2 && this.day > 28) {
                this.day = 28;
            }
            if (this.month && [2, 4, 6, 9, 11].includes(this.month) && this.day > 30){
                this.day = 30;
            }
        }
        this.emitValueIfValid();
        this.mOnTouched();
    }

    yearInputHandler(e: any) {
        const newValue = parseInt(e.target.value)
        if (isNaN(newValue) || newValue > 2050 || newValue < 1) {
            e.target.value = null;
            this.year = null
            return
        }
        this.year = newValue
        this.emitValueIfValid();
    }

    emitValueIfValid() {
        if(this.year && this.month && this.day) {
            const dateValue = new Date(`${this.year}-${this.month}-${this.day}`);
            this.mOnChange(dateValue);
            
        }else {
            this.mOnChange(null);
        }
    }

    writeValue(obj: any): void {
        if(obj && !isNaN(new Date(obj).getTime())) {
            const value = new Date(obj);
            this.day = value.getDate();
            this.month = value.getMonth() + 1;
            this.year = value.getFullYear();
        }
    }
    registerOnChange(fn: any): void {
        this.mOnChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.mOnTouched = fn;
    }

}
