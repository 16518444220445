<form [formGroup]="formGroup" [ngClass]="{'submitted': isSubmitted}">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-label">First Name</label>
        <input type="text" class="form-control" formControlName="firstName" placeholder="enter your first name">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-label">Last Name</label>
        <input type="text" class="form-control" formControlName="lastName" placeholder="enter your last name">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="form-label">Company Name</label>
        <input type="text" class="form-control" formControlName="companyName" placeholder="enter your Company name">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label class="form-label">Designation</label>
        <input type="text" class="form-control" formControlName="designation" placeholder="Designation">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="email" class="form-control" formControlName="email" placeholder="enter email address">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label">Mobile Number</label>
        <input type="text" class="form-control" formControlName="mobile" placeholder="+971 55 555 5555">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <label class="form-label">Organization Size</label>
            <select class="form-select" formControlName="orgSize">
                <option value="1-10">1-10</option>
                <option value="10-100">10-100</option>
                <option value="100-1000">100-1000</option>
                <option value="more than 1000">more than 1000</option>
            </select>
        </div>
    </div>
  </div>
  <legend class="mt-4">Location</legend>
  <div formGroupName="location">
    <div class="row mb-2">
      <div class="col">
        <input type="text" class="form-control mb-2" formControlName="addressLine1" placeholder="Address line 1">
        <input type="text" class="form-control" formControlName="addressLine2" placeholder="Address line 2">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <input type="text" class="form-control" formControlName="city" placeholder="City / Town">
      </div>
      <div class="col-6">
        <input type="text" class="form-control" formControlName="state" placeholder="State / Province / Emirate">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <input type="text" class="form-control" formControlName="postalCode" placeholder="Zip / Postal Code">
      </div>
    </div>
  </div>
</form>
