<nav
  class="navbar navbar-expand-lg navbar-light bg-light px-md-7 py-3"
  [ngClass]="[navbarStyle, pagesNavbar]"
>
  <div class="container-fluid">
    <a class="navbar-brand" href="javascript:void(0)">
      <img [src]="headerImage" alt="logo" className="home-logo" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      (click)="isMenuCollapsed = !isMenuCollapsed"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
    <div
      class="navbar-collapse"
      id="navbarSupportedContent"
      [ngbCollapse]="isMenuCollapsed"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            class="nav-link active"
            (click)="
              scrollToHomeSections('joining');
              isMenuCollapsed = !isMenuCollapsed
            "
            >Careers</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            (click)="
              scrollToHomeSections('brand'); isMenuCollapsed = !isMenuCollapsed
            "
            >Brands</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            (click)="
              scrollToHomeSections('tech-stacks');
              isMenuCollapsed = !isMenuCollapsed
            "
            >Our Tech</a
          >
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            (click)="
              scrollToHomeSections('joining');
              isMenuCollapsed = !isMenuCollapsed
            "
            >Contact Us</a
          >
        </li>
      </ul>
      <button
        class="btn btn-danger"
        [routerLink]="['/', 'get-started']"
        [ngClass]="showBtn"
      >
        Get Started
      </button>
    </div>
  </div>
</nav>
