import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { countries } from "./countries";
import { nationalities } from "./nationalities"

export interface Country {
    name: string;
    code: string;
}

export interface Nationality {
    num_code: string,
    alpha_2_code: string,
    alpha_3_code: string,
    en_short_name: string,
    nationality: string
}

@Injectable()
export class CountriesService {
    getCountries(): Observable<Country[]> {
        return of(countries)
    }
    getNationalities(): Observable<Nationality[]> {
        return of(nationalities)
    }
}
