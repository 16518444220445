import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-mockups',
  templateUrl: './mockups.component.html',
  styleUrls: ['./mockups.component.scss'],
  animations: [
    trigger('leftImg1Animation', [
      state('show-img1', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide-img1', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('show-img1 => hide-img1', animate('1000ms ease-in-out')),
      transition('hide-img1 => show-img1', animate('1000ms ease-in-out'))
    ]),
    trigger('downImg2Animation', [
      state('show-img2', style({
        opacity: 1,
        transform: "*"
      })),
      state('hide-img2', style({
        opacity: 0,
        transform: "translateY(-100%)"
      })),
      transition('show-img2 => hide-img2', animate('1000ms ease-in-out')),
      transition('hide-img2 => show-img2', animate('1000ms ease-in-out'))
    ]),
    trigger('leftImg3Animation', [
      state('show-img3', style({
        opacity: 1,
        transform: "*"
      })),
      state('hide-img3', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('hide-img3 => show-img3', animate('1000ms ease-in-out'))
    ]),
    trigger('upImg4Animation', [
      state('show-img4', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide-img4', style({
        opacity: 0,
        transform: "translateY(100%)"
      })),
      transition('hide-img4 => show-img4', animate('1000ms ease-in-out'))
    ]),
    trigger('rightImg5Animation', [
      state('show-img5', style({
        opacity: 1,
        transform: '*'
      })),
      state('hide-img5', style({
        opacity: 0,
        transform: "translateX(100%)"
      })),
      transition('hide-img5 => show-img5', animate('1000ms ease-in-out'))
    ]),
    trigger('leftImg6Animation', [
      state('show-img6', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide-img6', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('hide-img6 => show-img6', animate('1000ms ease-in-out'))
    ]),
    trigger('leftImg1smAnimation', [
      state('show-img1sm', style({
        opacity: 1,
        transform: "*"
      })),
      state('hide-img1sm', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('hide-img1sm => show-img1sm', animate('1000ms ease-in-out'))
    ]),
    trigger('rightImg2smAnimation', [
      state('show-img2sm', style({
        opacity: 1,
        transform: "*"
      })),
      state('hide-img2sm', style({
        opacity: 0,
        transform: "translateX(100%)"
      })),
      transition('hide-img2sm => show-img2sm', animate('1000ms ease-in-out'))
    ]),
    trigger('leftImg3smAnimation', [
      state('show-img3sm', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide-img3sm', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('hide-img3sm => show-img3sm', animate('1000ms ease-in-out'))
    ]),
    trigger('upImg4smAnimation', [
      state('show-img4sm', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide-img4sm', style({
        opacity: 0,
        transform: "translateY(100%)"
      })),
      transition('hide-img4sm => show-img4sm', animate('1000ms ease-in-out'))
    ]),
  ]
})
export class MockupsComponent implements OnInit {
  @ViewChild('imgEl1') public img1El?: ElementRef;
  @ViewChild('imgEl2') public img2El?: ElementRef;
  @ViewChild('imgEl3') public img3El?: ElementRef;
  @ViewChild('imgEl4') public img4El?: ElementRef;
  @ViewChild('imgEl5') public img5El?: ElementRef;
  @ViewChild('imgEl6') public img6El?: ElementRef;
  @ViewChild('imgEl1sm') public img1smEl?: ElementRef;
  @ViewChild('imgEl2sm') public img2smEl?: ElementRef;
  @ViewChild('imgEl3sm') public img3smEl?: ElementRef;
  @ViewChild('imgEl4sm') public img4smEl?: ElementRef;

  img1 = 'hide-img1';
  img2 = 'hide-img2';
  img3 = 'hide-img3';
  img4 = 'hide-img4';
  img5 = 'hide-img5';
  img6 = 'hide-img6';
  img1sm = 'hide-img1sm';
  img2sm = 'hide-img2sm';
  img3sm = 'hide-img3sm';
  img4sm = 'hide-img4sm';
  constructor() { }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {

    const img1Position = this.img1El?.nativeElement.offsetTop;
    const img2Position = this.img2El?.nativeElement.offsetTop;
    const img3Position = this.img3El?.nativeElement.offsetTop;
    const img4Position = this.img4El?.nativeElement.offsetTop;
    const img5Position = this.img5El?.nativeElement.offsetTop;
    const img6Position = this.img6El?.nativeElement.offsetTop;
    const img1SmPosition = this.img1smEl?.nativeElement.offsetTop;
    const img2SmPosition = this.img2smEl?.nativeElement.offsetTop;
    const img3SmPosition = this.img3smEl?.nativeElement.offsetTop;
    const img4SmPosition = this.img4smEl?.nativeElement.offsetTop;


    const scrollPosition = window.pageYOffset;
    if (scrollPosition >= img1Position - 700) {
      console.log("scroll img1", img1Position)
      this.img1 = 'show-img1'
    }
    if (scrollPosition >= img2Position - 700) {
      this.img2 = 'show-img2'
    }
    if (scrollPosition >= img3Position - 700) {
      this.img3 = 'show-img3'
    }
    if (scrollPosition >= img4Position - 600) {
      this.img4 = 'show-img4'
    }
    if (scrollPosition >= img5Position - 700) {
      this.img5 = 'show-img5'
    }
    if (scrollPosition >= img6Position - 600) {
      this.img6 = 'show-img6'
    }
    if (scrollPosition >= img1SmPosition - 600) {
      this.img1sm = 'show-img1sm'
    }
    if (scrollPosition >= img2SmPosition - 600) {
      this.img2sm = 'show-img2sm'
    }
    if (scrollPosition >= img3SmPosition - 550) {
      this.img3sm = 'show-img3sm'
    }
    if (scrollPosition >= img4SmPosition - 550) {
      this.img4sm = 'show-img4sm'
    }
  }

  ngOnInit(): void {
  }

}
