import { FormGroup } from '@angular/forms';

export abstract class BaseFormComponent {
    abstract formGroup: FormGroup;
    abstract key: string;
    isSubmitted: boolean = false;

    validate() {
        return this.formGroup.valid
    }

    save() {
        this.isSubmitted = true;
        if(this.formGroup.invalid) {
            return false;
        }
        var currentValue = localStorage.getItem(this.key);
        var currentData = {};
        try {
            if (currentValue) {
                currentData = JSON.parse(currentValue);
            }
        } catch(e) {
            console.debug('Error parsing current data.')
        }
        Object.assign(currentData, this.formGroup.value);
        localStorage.setItem(this.key, JSON.stringify(currentData));
        return true;
    }

    load() {
        setTimeout(() => {
            var currentValue = localStorage.getItem(this.key);
            var currentData = {};
            try {
                if (currentValue) {
                    currentData = JSON.parse(currentValue);
                }
            } catch(e) {
                console.debug('Error parsing current data.')
            }
            this.formGroup.patchValue(currentData);
        }, 1)
    }
}
