export class FileUpload {
    name?: string;
    url?: string;
    path?: string;
    error?: string;
    file: File;

    constructor(file: File) {
      this.file = file;
    }
}
