<section id="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-12 mb-4">
        <div class="footer-logo">
          <img src="assets/images/footer/academo-logo-alt.png" alt="logo" />
        </div>
      </div>
      <div class="col-lg-3 col-6 mb-4">
        <h4 class="title mb-3">Legal</h4>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToTop('/pages/privacy-policy')"
              >Privacy Policy</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToTop('/pages/payment-policy')"
              >Payment Policy, T&C</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToTop('/pages/anti-slavery')"
              >Anti-Slavery Policy</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="revokeCookie()">Cookie Settings</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-6 mb-4">
        <h4 class="title mb-3">Resources</h4>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToHomeSections('hero-section')"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToHomeSections('joining')"
              >Careers</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToHomeSections('brand')"
              >Brands</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToHomeSections('tech-stacks')"
              >Our Tech</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="scrollToHomeSections('joining')"
              >Contact</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-12">
        <h4 class="title mb-3">Address</h4>
        <p>Office No. 45, Level 29 - 2902</p>
        <p>Marina Plaza, Dubai Marina</p>
        <p>Dubai, U.A.E</p>
        <p>PO Box : 116878</p>
        <p>Call Us : <a href="tel:+97144245161">+971 4 424 5161</a></p>
        <a href="mailto:hello@academo.com" class="my-3">hello@academo.com</a>
        <ul class="navbar-nav my-4 flex-row">
          <li class="nav-item mr-3">
            <a
              class="nav-link"
              href="https://www.facebook.com/Atechy-Technologies-104153328563546/"
              target="_blank"
            >
              <img
                [src]="facebookImg"
                (mouseover)="
                  facebookImg = 'assets/images/footer/active-facebook.svg'
                "
                (mouseout)="facebookImg = 'assets/images/footer/facebook.svg'"
              />
            </a>
          </li>
          <li class="nav-item mr-3">
            <a
              class="nav-link"
              href="https://instagram.com/atechy_technologies?utm_medium=copy_link"
              target="_blank"
            >
              <img
                [src]="instagramImg"
                (mouseover)="
                  instagramImg = 'assets/images/footer/active-instagram.svg'
                "
                (mouseout)="instagramImg = 'assets/images/footer/instagram.svg'"
              />
            </a>
          </li>
          <li class="nav-item mr-3">
            <a
              class="nav-link"
              href="https://www.linkedin.com/company/atechy-tech/"
              target="_blank"
            >
              <img
                [src]="linkedInImg"
                (mouseover)="
                  linkedInImg = 'assets/images/footer/active-linked-in.svg'
                "
                (mouseout)="linkedInImg = 'assets/images/footer/linked-in.svg'"
              />
            </a>
          </li>
          <li class="nav-item mr-3">
            <a
              class="nav-link"
              href="https://twitter.com/Atechytech?s=08"
              target="_blank"
            >
              <img
                [src]="twitterImg"
                (mouseover)="
                  twitterImg = 'assets/images/footer/active-twitter.svg'
                "
                (mouseout)="twitterImg = 'assets/images/footer/twitter.svg'"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr class="my-0 mt-md-3" />
  <p class="text-center copy-right py-4 px-1 my-0">
    © 2021 Academo. All rights reserved.
  </p>
</section>
