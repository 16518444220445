<section id="hero-section" class="vh-lg-100">
  <div class="container-fluid">
    <div class="row" style="padding-top: 200px;">
      <div class="col-lg-6 col-md-12 d-lg-flex align-items-center">
        <div class="left-header text-lg-left text-md-center text-center pl-md-7">
          <h1 class="mb-0">Creating a</h1>
          <h1 class="pb-3">digital world</h1>
          <div id="header-description" class="text-lg-left text-md-center pb-3">
            Technology is transformational. We empower<br>
            businesses to seize opportunities and make<br>
            informed decisions through digitization.
          </div>
          <button class="btn btn-dark mt-4 mb-5 mx-0" [routerLink]="['/', 'get-started']">Get Started</button>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 pr-0" [@rightImgAnimation]="img">
        <div class="mockup">
          <img src="assets/images/header/hero-section-img-test.svg" class="w-100" />
        </div>
      </div>
    </div>
  </div>
</section>