<section id="medtech">
  <div class="container">
    <h1 class="title  text-sm-center">We care about Medtech</h1>
    <div class="legaltech">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="legal-info">
                <h4 class="title">Digital and personal connected health</h4>
                <p>
                  We provide digital solutions for health management, so
                  patients receive proactive but flexible and customised care.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="legal-info">
                <h4 class="title">Care coordination</h4>
                <p>
                  Bringing together health care services from multiple providers
                  and specialists can vastly improve a patient’s chance of
                  recovery.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="legal-info">
                <h4 class="title">
                  Patient engagement and experience management
                </h4>
                <p>
                  Technology is an effective tool for patient engagement
                  allowing them to make informed decisions in their own health
                  care journey.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="legal-info">
                <h4 class="title">Machine learning for healthcare</h4>
                <p>
                  Analysing huge volumes of data can help healthcare
                  professionals with diagnosis and treatment, leading to a more
                  direct and personalised service..
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="legal-info">
                <h4 class="title">Revenue cycle management solutions</h4>
                <p>
                  Get paid faster with automated billing. Our digital solutions
                  can reduce your workload whilst increasing staff productivity.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="legal-info">
                <h4 class="title">Physician liaison program management</h4>
                <p>
                  Having an effective liaison program can promote services and
                  treatments and turn referrals into profits.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 img-col">
          <img src="assets/images/medtech/medtech.png" alt="legal" />
        </div>
      </div>
    </div>
  </div>
</section>
