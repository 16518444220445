<section id="joining">
  <div class="container">
    <div class="overlay1">
      <img src="assets/images/brands/line1.png" alt="line" />
    </div>
    <div class="overlay2">
      <img src="assets/images/brands/line2.png" alt="line" />
    </div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="join-item">
          <h1 class="title">Join our team</h1>
          <p>
            We are always looking for new talent to join our tech hub in Dubai.
          </p>
          <p>
            If you have a passion for technology and want to make a positive
            impact on the future, contact us today.
          </p>
          <a href="info@academo.io">info@academo.io </a>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="join-item">
          <h1 class="title">Work with our staff</h1>
          <p>
            We want to partner with our clients. We invest in long-term
            relationships with both our clients and our staff.
          </p>
          <p>We always look for the best solutions, and so should you.</p>
          <a href="info@academo.io"> info@academo.io </a>
        </div>
      </div>
    </div>
  </div>
</section>
