import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { BaseFormComponent } from 'src/app/shared/base-form.component';
import { CountriesService, Country, Nationality } from "src/app/shared/services/countries.service";

@Component({
    selector: 'app-nationality-registration-form',
    templateUrl: './nationality-registration-form.component.html'
})
export class NationalityRegistrationFormComponent extends BaseFormComponent {
    key: string = "registrationFormData";
    formGroup = this.fb.group({
        nationality: ['', Validators.required],
        isUaeResidence: [''],
        visaType: ['', Validators.required],
        visaExpiry: [''],
        fundingMethod: ['', Validators.required],
        passportImage: [null, Validators.required],
        passportImage2: [{}],
        visaImage: [null],
        idImage: [{}]
    });
    nationalities: Nationality[] = [];
    selectedFiles = []

    constructor(
        private fb: FormBuilder,
        private countriesService: CountriesService
    ) {
        super();
        this.countriesService.getNationalities().subscribe({
            next: (nationalities) => {
                this.nationalities = nationalities
            }
        })
    }
}
