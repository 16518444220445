<app-navbar></app-navbar>
<div class="container">
  <div class="row">
    <div class="col mb-5">
      <p class="mt-5 mb-4">
        <strong>Payment Policy, T&C</strong>
      </p>
      <p>
        We may provide paid products and/or services within the Service. In that
        case, we may use Network International LLC as our payment provider.
      </p>
      <p>
        If you make a payment for our product or service on our website; the
        details you are asked to submit will be provided directly to our payment
        provider via a secured connection. And we accept payments online using
        Visa, Master, and all types of credit and debit cards Union Pay and
        Discover. And the cardholder must retain a copy of transaction records
        and merchant policy and rules.
      </p>
      <p>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express, and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <p>
        When You use Our Service to pay a product and/or service via bank
        transfer, We may ask You to provide information to facilitate this
        transaction and to verify Your identity.
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
