import { NgModule } from "@angular/core";
import { RegistrationRoutingModule } from './registration-routing.module'
import { StudentRegistrationComponent } from "./forms/student-registration.component";
import { BasicInfoRegistrationFormComponent } from "./forms/student/basic-info-registration-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NationalityRegistrationFormComponent } from "./forms/student/nationality-registration-from.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from "../shared/shared.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { RatingModule } from 'ngx-bootstrap/rating';
import { ExperiencesRegistrationFormComponent } from './forms/student/experiences-registration-form.component';
import { ConfirmationRegistrationFormComponent } from './forms/student/confirmation-registration-form.component';
import { SummeryRegistrationFormComponent } from './forms/student/summery-registration-form.component';
import { RegistrationService } from './registration.service';
import { CarouselModule } from "ngx-bootstrap/carousel";
import { EnterpriseRegistrationComponent } from "./forms/enterprise-registration.component";
import { EnterpriseInfoRegistrationFormComponent } from "./forms/enterprise/enterprise-info-registration-form.component";

@NgModule({
    declarations: [
        EnterpriseRegistrationComponent,
        StudentRegistrationComponent,
        BasicInfoRegistrationFormComponent,
        NationalityRegistrationFormComponent,
        ExperiencesRegistrationFormComponent,
        ConfirmationRegistrationFormComponent,
        SummeryRegistrationFormComponent,
        EnterpriseInfoRegistrationFormComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        BsDatepickerModule.forRoot(),
        CarouselModule.forRoot(),
        RatingModule,
        SharedModule,
        RegistrationRoutingModule,
    ],
    providers: [
        RegistrationService
    ]
})
export class RegistrationModule {}
