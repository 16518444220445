<form [formGroup]="formGroup" [ngClass]="{'submitted': isSubmitted}">
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">What is your nationality</label>
                <ng-select class="custom"
                    [items]="nationalities"
                    bindLabel="nationality"
                    bindValue="nationality"
                    placeholder="please select your nationality"
                    formControlName="nationality"></ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">Do you live in UAE?</label>
                <div class="form-input-group mt-2">
                    <div class="form-check form-check-inline">
                      <input id="isResidenceRadio" type="radio" class="form-check-input" formControlName="isUaeResidence" name="isUaeResidence" value="yes">
                      <label class="form-check-label" for="isResidenceRadio">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input id="notResidenceRadio" type="radio" class="form-check-input" formControlName="isUaeResidence" name="isUaeResidence" value="no">
                      <label class="form-check-label" for="notResidenceRadio">No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">What type of visa do you currently have?</label>
                <select class="form-select" formControlName="visaType">
                    <option value="visit-visa">Visit Visa</option>
                    <option value="employment-visa">Employment Residency</option>
                    <option value="family-residency">Family Residency</option>
                    <option value="uae-nationals">UAE Nationals</option>
                </select>
            </div>
        </div>
    </div>

    <div *ngIf="formGroup.value.visaType !=='uae-nationals'" class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">What date does your current visa expire?</label>
                    <app-date-input formControlName="visaExpiry"></app-date-input>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">Programme type</label>
                <select class="form-select" formControlName="fundingMethod">
                    <option value="15k-upfront">3 months bootcamp (15,000 AED upfront)</option>
                    <option value="75k-upfront">12 months work placement (75,000 AED upfront)     </option>
                    <option value="90k-funded">12 months working bootcamp (90,000 AED payment plan)</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">Passport Page 1</label>
                <app-file-uploader formControlName="passportImage"></app-file-uploader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">Passport Page 2 (optional)</label>
                <app-file-uploader formControlName="passportImage2"></app-file-uploader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">Visa (optional)</label>
                <app-file-uploader formControlName="visaImage"></app-file-uploader>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group">
                <label class="form-label">Emirates ID (optional)</label>
                <app-file-uploader formControlName="idImage"></app-file-uploader>
            </div>
        </div>
    </div>
</form>
