<section id="ai">
  <div class="container">
    <h1 class="title">We use AI to shape our future</h1>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="ai-feature-description">
          <img
            src="assets//images/icons/atechy-icons-aq.svg"
            alt="ai"
          />
          <h4 class="title">Natural Language Processing</h4>
          <p>
            NLP enables computers to convert human speech into a programming
            language. This field of artificial intelligence can be used to
            simplify your processes, making the most complex projects possible.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="ai-feature-description">
          <img
            src="assets//images/icons/atechy-icons-robotic.svg"
            alt="ai"
          />
          <h4 class="title">Robotic Process Automation</h4>
          <p>
            RPA is an easy to programme software designed to complete a series
            of basic, interconnected tasks. We can help you remove the monotony
            of repetitive jobs with Robotic Process Automation.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="ai-feature-description">
          <img src="assets//images/icons/outline.svg" alt="ai" />
          <h4 class="title">Business Intelligence and AI software</h4>
          <p>
            Business Intelligence (BI) can help you make informed decisions by
            using technology to compile and analyse your data. AI software can
            also empower computer systems to make data-driven decisions without
            human interaction.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="ai-feature-description">
          <img
            src="assets/images/icons/atechy-icons-technical.svg"
            alt="ai"
          />
          <h4 class="title">Machine Learning</h4>
          <p>
            Machine Learning can filter huge amounts of data and actively learn
            and adapt to its findings without human intervention. We help
            businesses with limited resources and time access a wealth of
            untapped knowledge.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="ai-feature-description">
          <img
            src="assets/images/icons/atechy-icons-dev-ops.svg"
            alt="ai"
          />
          <h4 class="title">Intelligent Document Recognition</h4>
          <p>
            We increase a company’s efficiency by providing AI that imports,
            stores and classifies different documents within the same system.
            IDR interprets patterns and information at higher speed, lower cost,
            with increased accuracy than human intelligence.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="ai-feature-description">
          <img
            src="assets/images/icons/atechy-icons-analytics.svg"
            alt="ai"
          />
          <h4 class="title">Regulatory Compliance Monitoring</h4>
          <p>
            We carry out compliance monitoring to make sure businesses are
            meeting their regulatory obligations. Internal auditing identifies
            areas of noncompliance, advises on mitigating solutions and designs
            strategies to minimise your risk.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
