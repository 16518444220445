import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { DocumentReference } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  CountriesService,
  Nationality,
} from 'src/app/shared/services/countries.service';
import { GetStartedService } from '../get-started/get-started.service';
import { Application } from './models/application.interface';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent implements OnInit {
  headerImage = 'assets/images/header/Academo_logo.png';
  isSubmitted = false;
  isRequestSubmitted = false;
  formGroup = this.fb.group({
    companyName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    country: ['', Validators.required],
    brief: ['', Validators.required],
  });
  en_short_name: Nationality[] = [];
  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private analytics: AngularFireAnalytics,
    private getStartedService: GetStartedService,
    private toastr: ToastrService
  ) {
    this.countriesService.getNationalities().subscribe({
      next: (en_short_name) => {
        this.en_short_name = en_short_name;
      },
    });
  }

  ngOnInit() {}
  sendRequestButton(e: any) {
    e.preventDefault();
    this.isSubmitted = true;
    if (this.formGroup.invalid) {
      this.analytics.logEvent('Form Validation Failed');
      return;
    } else {
      this.isRequestSubmitted = true;
    }
    this.formGroup.disable();
    var currentValue = localStorage.getItem('get-started');
    var currentData: Partial<Application> = {};
    try {
      if (currentValue) {
        currentData = JSON.parse(currentValue);
      }
    } catch (e) {
      localStorage.clear();
      console.debug('Error parsing current data.');
    }
    Object.assign(currentData, this.formGroup.value);
    this.getStartedService.create(currentData as Application)?.subscribe({
      next: (doc: DocumentReference) => {
        currentData.uid = doc.id;
        localStorage.setItem('get-started', JSON.stringify(currentData));
        this.toastr.success('Your application has been submitted.');
        this.formGroup.enable();
      },
      error: (error: Error) => {
        this.analytics.logEvent('ApplicationSaveError', { data: currentData });
        this.toastr.error(
          'Unable to save your application please try again later.',
          'Something Went Wrong'
        );
        this.formGroup.enable();
      },
      complete: () => {},
    });
  }
}
