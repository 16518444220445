<div class="row mb-4 pb-4">
    <div class="col">
        <h5 class="text-center">What happens next?</h5>
    </div>
</div>
<div class="row mb-4 pb-4">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <img src="assets/images/contract-icon.svg" alt="Contract">
            </div>
            <div>
                <h5 class="text-accent text-left">Receive and sign contract</h5>
                <div class="text-left">
                    If you are accepted onto the course, you will recieve a contract which will need to sign and handed back to an Academo representative.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mb-4 pb-4">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <img src="assets/images/email-icon.svg" alt="Email">
            </div>
            <div>
                <h5 class="text-accent text-left">Offer of employment</h5>
                <div class="text-left">
                    Your employer will send you an offer of employment and a start date when you will be required to start work.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mb-4 pb-4">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <img src="assets/images/visa-icon.svg" alt="Visa">
            </div>
            <div>
                <h5 class="text-accent text-left">Visa processing</h5>
                <div class="text-left">
                    Your employer will arrange for your visa to be processed which will give you permission to work in dubai.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mb-4 pb-4">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <img src="assets/images/funding-icon.svg" alt="Funding">
            </div>
            <div>
                <h5 class="text-accent text-left">Funding your internship</h5>
                <div class="text-left">
                    A third party funding company will assist you in funding your internship. This will generally be handled by them and your employer.
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mb-4 pb-4">
    <div class="col">
        <div class="d-flex align-items-center">
            <div class="mr-4">
                <img src="assets/images/education-icon.svg" alt="">
            </div>
            <div>
                <h5 class="text-accent text-left">Start learning</h5>
                <div class="text-left">
                    Once your internship has been funded, you are free to start learning with Academo. You can choose which career path you want to take and we will build a curriculum to suit you.
                </div>
            </div>
        </div>
    </div>
</div>
