import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from "@angular/fire/firestore";
import { from, Observable } from "rxjs";
import { Application } from "./models/application.interface";

@Injectable()
 export class GetStartedService {
     applicationsCollection?: AngularFirestoreCollection<Application>
     constructor(firestore: AngularFirestore) {
         this.applicationsCollection = firestore.collection<Application>("getStartedApplications");
     }
     create(application: Application): Observable<DocumentReference<Application>> | null {     
         if (this.applicationsCollection) {
             return from(this.applicationsCollection.add(application))    
         } else {  
             console.error("firestore uninitialized.");
         }
         return null;
     }
     update(applicationId: string, changes: Partial<Application>): Observable<void> | null {
         if (!applicationId) {
             console.error("no application id");
             return null;
         }
         if(this.applicationsCollection) {
             return from(this.applicationsCollection.doc(applicationId).update(changes));
         } else {
             console.error("firestore uninitialized.");
         }
         return null;
     }
}
