<form [formGroup]="formGroup" [ngClass]="{'submitted': isSubmitted}">
<div class="row" *ngIf="fundingMethod === '15k-upfront'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="paymentConfirmed" id="paymentCheckbox">
            <label for="paymentCheckbox" class="form-check-label">I understand that the full price of the education is AED 15,000 paid upfront</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod === '75k-upfront'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="paymentConfirmed" id="paymentCheckbox">
            <label for="paymentCheckbox" class="form-check-label">I understand that the full price of the education is AED 75,000 paid upfront</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod === '90k-funded'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="paymentConfirmed" id="paymentCheckbox">
            <label for="paymentCheckbox" class="form-check-label">I understand that the full price of the education in AED 198,000.</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod === '90k-funded'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="employerPaymentConfirmed" id="employerPaymentConfirmedCheckbox">
            <label for="employerPaymentConfirmedCheckbox" class="form-check-label">I confirm that Atechy will be funding AED 108,000 of my education</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod === '90k-funded'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="fundingConfirmed" id="fundingConfirmedCheckbox">
            <label for="fundingConfirmedCheckbox" class="form-check-label">I confirm that Atechy will help me arrange personal funding for AED 90,000 for my education.</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod !== '90k-funded'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="ageConfirmed" id="over18ConfirmedCheckbox">
            <label for="over18ConfirmedCheckbox" class="form-check-label">I confirm that I am over 18 years old.</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod === '90k-funded'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="ageConfirmed" id="over21ConfirmedCheckbox">
            <label for="over21ConfirmedCheckbox" class="form-check-label">I confirm that I am over 21 years old.</label>
        </div>
    </div>
</div>
<div class="row" *ngIf="fundingMethod === '90k-funded'">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="creditRecordConfirmed" id="creditRecordConfirmedCheckbox">
            <label for="creditRecordConfirmedCheckbox" class="form-check-label">I confirm that I have clean or no credit record in UAE.</label>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" formControlName="criminalRecordConfirmed" id="criminalRecordConfirmedCheckbox">
            <label for="criminalRecordConfirmedCheckbox" class="form-check-label">I have a clean UAE criminal record.</label>
        </div>
    </div>
</div>
</form>
