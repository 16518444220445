import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseFormComponent } from 'src/app/shared/base-form.component';

@Component({
    selector: 'app-experiences-registration-form',
    templateUrl: './experiences-registration-form.component.html',
    animations: [
        trigger(
          'inOutAnimation',
          [
            transition(
              ':enter',
              [
                style({ height: 0, opacity: 0 }),
                animate('0.3s ease-out',
                        style({ height: 70, opacity: 1 }))
              ]
            ),
            transition(
              ':leave',
              [
                style({ height: 70, opacity: 1 }),
                animate('0.3s ease-in',
                        style({ height: 0, opacity: 0 }))
              ]
            )
          ]
        )
      ]
})
export class ExperiencesRegistrationFormComponent extends BaseFormComponent {
    key: string = 'registrationFormData';

    formGroup = this.fb.group({
        isUiUx: [false],
        isFrontEnd: [false],
        isBackEnd: [false],
        uiUxExperience: [0],
        frontendExperience: [0],
        backendExperience: [0]
    });

    constructor(
        private fb: FormBuilder
    ) {super();}

}
