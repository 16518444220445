import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
  animations: [
    trigger('rightImgAnimation', [
      state('show-img', style({
        opacity: 1,
        transform: "translateX(30px)"
      })),
      state('hide-img', style({
        opacity: 0,
        transform: "translateX(100%)"
      })),
      transition('hide-img => show-img', animate('1000ms ease-in-out'))
    ]),
  ]
})
export class HeroSectionComponent implements OnInit {

  img = 'hide-img';
  constructor() { }
  ngOnInit(): void {
    setTimeout(() => {
      this.img = 'show-img';
    }, 500)
   }
}
