<section id="tech-stacks">
  <div class="container">
    <h1 class="title text-center">Our tech stacks</h1>
    <tabset>
      <tab>
        <ng-template tabHeading>
          <img
            src="assets/images/icons/atechy-icons-soft-creation.svg"
            alt="dc"
          />
          <p>Software development</p>
        </ng-template>
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="technology text-center">
            <img src="assets/images/stacks/net-logo.png" alt="dc" />
            <p>.NET</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/python-logo.png" alt="dc" />
            <p>PYTHON</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/node-js-logo.png" alt="dc" />
            <p>Node.js</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/magento-logo.png" alt="dc" />
            <p>Magento</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/php-logo.png" alt="dc" />
            <p>PHP</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/javascript-logo.png" alt="dc" />
            <p>JAVASCRIPT</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/java-logo.png" alt="dc" />
            <p>JAVA</p>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading>
          <img src="assets/images/icons/atechy-icons-web.svg" alt="dc" />
          <p>Web development</p>
        </ng-template>
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="technology text-center">
            <img src="assets/images/stacks/react-logo.png" alt="dc" />
            <p>React</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/angular-logo.png" alt="dc" />
            <p>ANGULAR JS</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/symfony-logo.png" alt="dc" />
            <p>SYMFONY</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/javascript-logo.png" alt="dc" />
            <p>JAVASCRIPT</p>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading>
          <img src="assets/images/icons/atechy-icons-payment.svg" alt="dc" />
          <p>Mobile development</p>
        </ng-template>
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="technology text-center">
            <img src="assets/images/stacks/android-logo.png" alt="dc" />
            <p>ANDRIOD</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/ios-logo.png" alt="dc" />
            <p>IOS</p>
          </div>
          <div class="technology text-center">
            <img src="assets/images/stacks/java-logo.png" alt="dc" />
            <p>JAVA</p>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
</section>
