<div class="form-control" style="padding: 8px;height: 48px; overflow: hidden;">
    <input #fileSelector type="file"
        class="text-nowrap text-truncate"
        accept="image/png, image/jpeg, application/pdf"
        (change)="selectFile($event)" hidden>
    <div class="d-flex justify-content-between align-items-center ml-4" *ngIf="!selectedFile && !this.value?.fileName">
        <div>Please select file to upload</div>
        <button class="btn btn-success btn-sm" (click)="fileSelector.click()">Select File</button>
    </div>
    <div class="d-flex justify-content-between align-items-center ml-4" *ngIf="selectedFile || this.value?.fileName">
        <div class="text-truncate">{{selectedFile?.name || this.value?.fileName}}</div>
        <button *ngIf="isUploading" class="btn btn-outline-danger btn-sm" (click)="cancelUpload()">Cancel</button>
        <div *ngIf="(!isUploading && !fileUpload?.error) || this.value?.fileName" class="d-flex align-items-center">
            <div  class="text-success text-nowrap">
                <fa-icon [icon]="faCheck"></fa-icon>
                Uploaded
            </div>
            <button class="btn btn-danger btn-sm ml-2" (click)="clearFile()">
                <fa-icon [icon]="faTrash"></fa-icon>
            </button>
        </div>
        <div *ngIf="!isUploading && fileUpload?.error">
            <button class="btn btn-warning btn-sm" (click)="clearFile()">Retry</button>
        </div>
    </div>
</div>
<div class="mb-2">
    <progressbar *ngIf="isUploading" [animate]="true" [value]="progress" type="success" style="height: 5px;position: relative;top: -5px;"></progressbar>
</div>
