/* Firebase storage interface service */

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FileUpload } from '../models/file-upload.model';
import { catchError, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    private basePath = '/uploads';

    constructor(
        private db: AngularFireDatabase,
        private storage: AngularFireStorage
    ) {}

    pushFileToStorage(fileUpload: FileUpload, onComplete: (result: FileUpload) => void): AngularFireUploadTask {
        const filePath = `${this.basePath}/${Date.now()}-${fileUpload.file.name}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, fileUpload.file);

        uploadTask.snapshotChanges().pipe(
            finalize(() => {
                storageRef.getDownloadURL().subscribe(downloadURL => {
                    fileUpload.url = downloadURL;
                    fileUpload.path = filePath;
                    fileUpload.name = fileUpload.file.name;
                    onComplete(fileUpload);
                });
            })
        ).subscribe({
            error: (err: Error) => {
                fileUpload.error = err.message;
                onComplete(fileUpload)
            }
        });

        return uploadTask;
    }

    private saveFileData(fileUpload: FileUpload): void {
        this.db.list(this.basePath).push(fileUpload);
    }
}
