import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { StatsComponent } from './components/stats/stats.component';
import { BrandComponent } from './components/brand/brand.component';
import { AiComponent } from './components/ai/ai.component';
import { TechFeaturesComponent } from './components/tech-features/tech-features.component';
import { MedtechComponent } from './components/medtech/medtech.component';
import { TechStacksComponent } from './components/tech-stacks/tech-stacks.component';
import { JoiningComponent } from './components/joining/joining.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsShowcaseComponent } from './components/projects-showcase/projects-showcase.component';
import { MockupsComponent } from './components/mockups/mockups.component';

@NgModule({
  declarations: [
    HomeComponent,
    HeroSectionComponent,
    StatsComponent,
    BrandComponent,
    AiComponent,
    TechFeaturesComponent,
    MedtechComponent,
    TechStacksComponent,
    JoiningComponent,
    ProjectsShowcaseComponent,
    MockupsComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    TabsModule.forRoot(),
    SharedModule,
    NgbModule,
  ],
})
export class HomeModule {}
