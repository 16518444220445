<section id="brand">
  <div class="container">
    <h1 class="title text-center">Brands we create for</h1>
    <div class="brands-list">
      <div class="overlay1">
        <img src="assets/images/brands/line1.png" alt="line" />
        <img src="assets/images/brands/line1.png" alt="line" />
        <img src="assets/images/brands/line1.png" alt="line" />
      </div>
      <div class="overlay2">
        <img src="assets/images/brands/line1.png" alt="line" />
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img
              src="assets/images/brands/atechy-business-logo.svg"
              alt="brand"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/my-business-logo.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/areande-blue-logo.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img
              src="assets//images/brands/atechy-health-logo.svg"
              alt="brand"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img
              src="assets/images/brands/myflightpass-colour.svg"
              alt="brand"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets//images/brands/legal-blue-logo.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/trusted-logo.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/test2tly-logo.svg" alt="brand" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/funda-logo.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/my-family.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets//images/brands/rowan-ai-logo.svg" alt="brand" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img
              src="assets/images/brands/people-light-blue-logo.svg"
              alt="brand"
            />
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img
              src="assets/images/brands/atechy-technologies-logo.svg"
              alt="brand"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6">
          <div class="brand">
            <img src="assets/images/brands/teamio-logo.svg" alt="brand" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
