import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medtech',
  templateUrl: './medtech.component.html',
  styleUrls: ['./medtech.component.scss']
})
export class MedtechComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
