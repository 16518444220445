<form [formGroup]="formGroup" [ngClass]="{'submitted': isSubmitted}">
  <div class="row mb-4">
    <div class="col">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="font-weight-bold text-left">Do you have any experience in UI/UX Design?</div>
        <div class="custom-control custom-switch custom-switch-md ml-auto">
          <input type="checkbox" class="custom-control-input" id="uiUxSwitch" formControlName="isUiUx">
          <label class="custom-control-label" for="uiUxSwitch"></label>
        </div>
      </div>
      <div *ngIf="formGroup.value.isUiUx" [@inOutAnimation]>
        <label class="text-left">Rate yourself out of 10 (1 is bad and 10 is amazing)</label>
        <div class="mb-2">
          <rating [max]="10" [customTemplate]="tt" formControlName="uiUxExperience"></rating>
          <ng-template #tt let-i="index" let-v="value">
            <button class="btn btn-{{i < v ? 'rating-active' : 'outline-primary'}} mr-1 mb-1 btn-sm">
              {{i + 1}}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="font-weight-bold text-left">Do you have any experience in Front end development?</div>
        <div class="custom-control custom-switch custom-switch-md ml-auto">
          <input type="checkbox" class="custom-control-input" id="frontEndSwitch" formControlName="isFrontEnd">
          <label class="custom-control-label" for="frontEndSwitch"></label>
        </div>
      </div>
      <div *ngIf="formGroup.value.isFrontEnd" [@inOutAnimation]>
        <label>Rate yourself out of 10 (1 is bad and 10 is amazing)</label>
        <div class="mb-2">
          <rating [max]="10" [customTemplate]="tt" formControlName="frontendExperience"></rating>
          <ng-template #tt let-i="index" let-v="value">
            <button class="btn btn-{{i < v ? 'rating-active' : 'outline-primary'}} mr-1 mb-1 btn-sm">
              {{i + 1}}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="font-weight-bold text-left">Do you have any experience in Back end development?</div>
        <div class="custom-control custom-switch custom-switch-md ml-auto">
          <input type="checkbox" class="custom-control-input" id="backendSwitch" formControlName="isBackEnd">
          <label class="custom-control-label" for="backendSwitch"></label>
        </div>
      </div>
      <div *ngIf="formGroup.value.isBackEnd" [@inOutAnimation]>
        <label>Rate yourself out of 10 (1 is bad and 10 is amazing)</label>
        <div class="mb-2">
          <rating [max]="10" [customTemplate]="tt" formControlName="backendExperience"></rating>
          <ng-template #tt let-i="index" let-v="value">
            <button class="btn btn-{{i < v ? 'rating-active' : 'outline-primary'}} mr-1 mb-1 btn-sm">
              {{i + 1}}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</form>
