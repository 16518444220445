import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/base-form.component';
import { CountriesService, Country } from 'src/app/shared/services/countries.service';

@Component({
  selector: 'app-basic-info-registration-form',
  templateUrl: './basic-info-registration-form.component.html'
})
export class BasicInfoRegistrationFormComponent extends BaseFormComponent {
  key: string = "registrationFormData";
  formGroup = this.fb.group({
    ref: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    dateOfBirth: ['', Validators.required],
    gender: ['male', Validators.required],
    email: ['', Validators.required],
    mobile: ['', Validators.required],
    motherName: ['', Validators.required],
    address: this.fb.group({
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: [''],
      postalCode: [''],
    }),
    homeAddress: this.fb.group({
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: [''],
      postalCode: [''],
      country: ['', Validators.required]
    }),
    homeCountryPhone: ['', Validators.required],
    emergencyContact: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      relation: ['', Validators.required],
      phone: ['', Validators.required]
    })
  });
  countries: Country[] = [];
  lockRef = false;

  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private route: ActivatedRoute
  ) {
    super();
    this.countriesService.getCountries().subscribe({
      next: (countries: Country[]) => {
        this.countries = countries
      },
    });
    this.route.queryParams.subscribe(
      params => {
        const ref = params['ref'] || null;
        if (ref) {
          this.formGroup.patchValue({ref})
          this.formGroup.controls['ref'].disable()
        }
      }
    )
  }
}