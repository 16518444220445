import { Component, ViewChild } from "@angular/core";
import { DocumentReference } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { BaseFormComponent } from '../../shared/base-form.component';
import { BasicInfoRegistrationFormComponent } from './student/basic-info-registration-form.component';
import { ConfirmationRegistrationFormComponent } from './student/confirmation-registration-form.component';
import { ExperiencesRegistrationFormComponent } from './student/experiences-registration-form.component';
import { NationalityRegistrationFormComponent } from './student/nationality-registration-from.component';
import { StudentApplication } from '../models/student-application.interface';
import { RegistrationService } from '../registration.service';

const steps = [
    'basic-info', 'nationality', 'experiences', 'confirmation', 'summery'
]

@Component({
    selector: 'app-student-registration',
    templateUrl: './student-registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class StudentRegistrationComponent {
    step: typeof steps[number] = 'basic-info';
    forms: {[key:string]: BaseFormComponent} = {}
    key = 'registrationFormData';

    @ViewChild('basicInfoForm', {static: false})
    set basicInfoForm(component: BasicInfoRegistrationFormComponent) {
        if (component) {
            this.forms['basic-info'] = component;
            component.load();
        }
    }
    @ViewChild('nationalityForm', {static: false})
    set nationalityForm(component: NationalityRegistrationFormComponent) {
        if (component) {
            this.forms['nationality'] = component;
            component.load();
        }
    }
    @ViewChild('experiencesForm', {static: false})
    set experiencesFrom(component: ExperiencesRegistrationFormComponent) {
        if (component) {
            this.forms['experiences'] = component;
            component.load();
        }
    }
    @ViewChild('confirmationForm', {static: false})
    set confirmationForm(component: ConfirmationRegistrationFormComponent) {
        if (component) {
            this.forms['confirmation'] = component;
            component.load();
        }
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private registrationService: RegistrationService,
        private toastr: ToastrService
    ) {
        this.route.queryParams.subscribe(
            params => {
                this.step = params['step'] || 'basic-info'
            }
        )
    }

    next() {
        let currentStepIndex = steps.indexOf(this.step);
        if (currentStepIndex < steps.length) {
            switch(this.step) {
                case 'basic-info':
                    if (!this.forms['basic-info'].save()) {return}
                break;
                case 'nationality':
                    if (!this.forms['nationality'].save()) {return}
                break;
                case 'experiences':
                    if (!this.forms['experiences'].save()) {return}
                break;
                case 'confirmation':
                    if (!this.forms['confirmation'].save()) {return}
                    this.createApplication()
                    return;
                case 'summery':
                    this.router.navigate(['/'])
                    return;
                default:
                break;
            }
            console.log('next');
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {step: steps[currentStepIndex + 1]}
            })
        }
    }

    goBack() {
        let currentStepIndex = steps.indexOf(this.step);
        if (currentStepIndex <= 0) {
            this.router.navigate(['/'])
        } else {
            this.router.navigate([], {queryParams: {step: steps[currentStepIndex - 1]}})
        }
    }

    createApplication() {
        let applicationDataString = localStorage.getItem(this.key);
        let application: StudentApplication = {};
        if (applicationDataString) {
            try {
                application = JSON.parse(applicationDataString)
            } catch {
                console.error('error parsing application data');
                localStorage.setItem(this.key, "{}")
            }
            this.registrationService.createStudentApplication(application)?.subscribe({
                next: (doc: DocumentReference<StudentApplication>) => {
                    localStorage.setItem(this.key, '{}')
                    
                    this.toastr.success('Your application has been submitted successfully.', "Hooray!")
                    this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: {step: 'summery'}
                    });
                },
                error: (err) => {
                },
                complete: () => {}
            });
        } else {
            console.error('empty application data');
        }
    }
}
