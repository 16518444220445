import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-stacks',
  templateUrl: './tech-stacks.component.html',
  styleUrls: ['./tech-stacks.component.scss'],
})
export class TechStacksComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
