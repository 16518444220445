import { Component, ViewChild } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseFormComponent } from '../../shared/base-form.component';
import { EnterpriseApplication } from '../models/enterprise-application.interface';
import { RegistrationService } from '../registration.service';
import { EnterpriseInfoRegistrationFormComponent } from './enterprise/enterprise-info-registration-form.component';

const steps = ['enterprise-info'];

@Component({
  selector: 'app-enterprise-registration',
  templateUrl: './enterprise-registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class EnterpriseRegistrationComponent {
  step: typeof steps[number] = 'enterprise-info';
  forms: { [key: string]: BaseFormComponent } = {};
  key = 'enterpriseRegisterationForm';

  @ViewChild('enterpriseInfoForm', { static: false })
  set basicInfoForm(component: EnterpriseInfoRegistrationFormComponent) {
    if (component) {
      this.forms['enterprise-info'] = component;
      component.load();
    }
  }

  constructor(
    private router: Router,
    private registrationService: RegistrationService,
    private toastr: ToastrService
  ) {}
  next() {
    if (!this.forms['enterprise-info'].save()) {
      return;
    }
    this.createApplication();
  }

  createApplication() {
    let applicationDataString = localStorage.getItem(this.key);
    let application: EnterpriseApplication = {};
    if (applicationDataString) {
      try {
        application = JSON.parse(applicationDataString);
      } catch {
        console.error('error parsing application data');
        localStorage.setItem(this.key, '{}');
      }
      this.registrationService
        .createEnterpriseApplication(application)
        ?.subscribe({
          next: (doc: DocumentReference<EnterpriseApplication>) => {
            localStorage.setItem(this.key, '{}');
            this.toastr.success(
              'Your application has been submitted successfully.',
              'Hooray!'
            );
            this.router.navigate(['/']);
          },
          error: (err) => {
            console.error(err);
          },
          complete: () => {},
        });
    } else {
      console.error('empty application data');
    }
  }
  goBack() {
    this.router.navigate(['/']);
  }
}
