import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AntiSlaveryComponent } from './anti-slavery/anti-slavery.component';
import { PaymentPolicyComponent } from './payment-policy/payment-policy.component';

@NgModule({
  declarations: [
    PagesComponent,
    PrivacyPolicyComponent,
    AntiSlaveryComponent,
    PaymentPolicyComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule],
})
export class PagesModule {}
