<section id="mockups">
    <div class="container d-none d-sm-block">
        <div class="row align-items-end">
            <div class="col-sm-3 img3" #imgEl3 [@leftImg3Animation]="img3" style="padding-right: 25px;">
                <img src="assets/images/mockups/funda-card.svg" class="w-100 custom-shadow" />
            </div>
            <div class="col-sm-6 img2" #imgEl2 [@downImg2Animation]="img2" style="padding-right: 25px;">
                <img src="assets/images/mockups/areanda.svg" class="w-100 custom-shadow" />
            </div>
            <div class="col-sm-3 img5" #imgEl5 [@rightImg5Animation]="img5">
                <img src="assets/images/mockups/teamio-mobile.svg" class="w-100 custom-shadow" />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 d-flex justify-content-end" #imgEl6 [@leftImg6Animation]="img6">
                <img src="assets/images/mockups/live-chat.svg" class="custom-shadow img6" style=" justify-self: end;" />
            </div>
            <div class="col-sm-9" #imgEl4 [@upImg4Animation]="img4">
                <img src="assets/images/mockups/funda.svg" class="w-100 custom-shadow img4" />
            </div>
        </div>
    </div>
    <div class="container d-block d-sm-none">
        <div class="row align-items-end">
            <div class="col img1sm" #imgEl1sm [@leftImg1smAnimation]="img1sm">
                <img src="assets/images/mockups/funda-card-sm.svg" class="w-100 custom-shadow" />
            </div>
            <div class="col img2sm pr-0" #imgEl2sm [@rightImg2smAnimation]="img2sm" >
                <img src="assets/images/mockups/areanda-sm.svg" class="w-100 custom-shadow"/>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-end" #imgEl3sm [@leftImg3smAnimation]="img3sm">
                <img src="assets/images/mockups/teamio-sm.svg" class="custom-shadow img6"/>
            </div>
            <div class="col pr-0" #imgEl4sm [@upImg4smAnimation]="img4sm">
                <img src="assets/images/mockups/funda-sm.svg" class="w-100 custom-shadow img4" />
            </div>
        </div>
    </div>
</section>