// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDhr3rWHNGOJv2_IA7kZnQnAqJRBwuee6A",
    authDomain: "academo-1f455.firebaseapp.com",
    projectId: "academo-1f455",
    storageBucket: "academo-1f455.appspot.com",
    messagingSenderId: "986957404379",
    appId: "1:986957404379:web:e6db45269f9c55226f14fe",
    measurementId: "G-0T9RY5EC8L"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
