import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetStartedRoutingModule } from './get-started-routing.module';
import { GetStartedComponent } from './get-started.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    declarations: [
        GetStartedComponent
    ],
    imports: [
        CommonModule,
        GetStartedRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule
    ]
})
export class GetStartedModule {}
