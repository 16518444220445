import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anti-slavery',
  templateUrl: './anti-slavery.component.html',
  styleUrls: ['./anti-slavery.component.scss']
})
export class AntiSlaveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
