import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-policy',
  templateUrl: './payment-policy.component.html',
  styleUrls: ['./payment-policy.component.scss'],
})
export class PaymentPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
