import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { CountriesService } from './services/countries.service';
import { FileUploadService } from './services/file-upload.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateInputComponent } from './date-input/date-input.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    FileUploaderComponent,
    DateInputComponent,
    FooterComponent,
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProgressbarModule,
    FontAwesomeModule,
    NgbModule,
    RouterModule,
    CollapseModule.forRoot(),
  ],
  providers: [CountriesService, FileUploadService],
  exports: [
    FileUploaderComponent,
    DateInputComponent,
    NavbarComponent,
    FooterComponent,
  ],
})
export class SharedModule {}
