import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/base-form.component';
import { StudentApplication } from '../../models/student-application.interface';

@Component({
    selector: 'app-confirmation-registration-form',
    templateUrl: './confirmation-registration-form.component.html'
})
export class ConfirmationRegistrationFormComponent extends BaseFormComponent implements OnInit {
    key: string = 'registrationFormData';
    formGroup = this.fb.group({
        paymentConfirmed: [false, Validators.requiredTrue],
        employerPaymentConfirmed: [false, Validators.requiredTrue],
        fundingConfirmed: [false, Validators.requiredTrue],
        ageConfirmed: [false, Validators.requiredTrue],
        creditRecordConfirmed: [false, Validators.requiredTrue],
        criminalRecordConfirmed: [false, Validators.requiredTrue]
    });
    fundingMethod: string = '';

    constructor(
        private fb: FormBuilder,
        private router: Router
    ) {super();}

    ngOnInit() {
        try {
            const dataString = localStorage.getItem('registrationFormData');
            let data: StudentApplication = {};
            if (dataString) {
                data = JSON.parse(dataString) as StudentApplication
                this.fundingMethod = data.fundingMethod || ""
                if (this.fundingMethod !== '90k-funded') {
                    this.formGroup.patchValue({
                        creditRecordConfirmed: true,
                        employerPaymentConfirmed: true,
                        fundingConfirmed: true
                    });
                }
            }
        } catch {
            console.error('[ConfirmRegistrationComponent]:OnInit:parseFormData failed')
            alert("Unable to read form data, please start over.")
            localStorage.setItem('registrationFormData', '')
            this.router.navigate(['/', 'registration'], {
                queryParams: {step: 'basic-info'}
            });
        }
    }
}
