import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { EnterpriseApplication } from './models/enterprise-application.interface';
import { StudentApplication } from './models/student-application.interface';

@Injectable()
export class RegistrationService {
    studentApplicationsCollection?: AngularFirestoreCollection<StudentApplication>
    enterpriseApplicationsCollection?: AngularFirestoreCollection<EnterpriseApplication>
    constructor(firestore: AngularFirestore) {
        this.studentApplicationsCollection = firestore.collection<StudentApplication>('applications')
        this.enterpriseApplicationsCollection = firestore.collection<EnterpriseApplication>('enterpriseApplications')
    }
    createStudentApplication(application: StudentApplication): Observable<DocumentReference<StudentApplication>> | null {
        if (this.studentApplicationsCollection) {
            return from(this.studentApplicationsCollection.add(application))
        } else {
            console.error("firestore uninitialized.");
        }
        return null
    }
    createEnterpriseApplication(application: EnterpriseApplication): Observable<DocumentReference<EnterpriseApplication>> | null {
        if (this.enterpriseApplicationsCollection) {
            return from(this.enterpriseApplicationsCollection.add(application));
        } else {
            console.error("firestore uninitialized.");
        }
        return null
    }
}
