import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/base-form.component';

@Component({
  selector: 'app-enterprise-info-registration-form',
  templateUrl: './enterprise-info-registration-form.component.html'
})
export class EnterpriseInfoRegistrationFormComponent extends BaseFormComponent {
  key: string = "enterpriseRegisterationForm";
  formGroup = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyName: ['', Validators.required],
    designation: ['', Validators.required],
    email: ['', Validators.required],
    mobile: ['', Validators.required],
    orgSize: ['', Validators.required],
    location: this.fb.group({
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      city: ['', Validators.required],
      state: [''],
      postalCode: [''],
    }),
  });
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    super();
    this.route.queryParams.subscribe(
      params => {
        const companyName = params['companyName'] || null;
        if (companyName) {
          this.formGroup.patchValue({companyName})
          this.formGroup.controls['companyName'].disable()
        }
      }
    )
  }
}