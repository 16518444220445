<section id="stats">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="stats text-center">
          <h2 class="title">350+</h2>
          <p>Apps built in Fintech, Medtech and Edtech</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="stats text-center">
          <h2 class="title">2M</h2>
          <p>API requests per day peaking at 5000 requests per second</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="stats text-center">
          <h2 class="title">35</h2>
          <p>Brands across the globe</p>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-12">
        <div class="stats text-center">
          <h2 class="title">55%</h2>
          <p>Of all UK and Aus adults have used our platforms</p>
        </div>
      </div>
    </div>
  </div>
</section>
