import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AntiSlaveryComponent } from './anti-slavery/anti-slavery.component';
import { PaymentPolicyComponent } from './payment-policy/payment-policy.component';

const routes: Routes = [
  { path: '', component: PagesComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'payment-policy', component: PaymentPolicyComponent },
  { path: 'anti-slavery', component: AntiSlaveryComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
