<section id="tech-features" class="triangle">
  <div id="earth"></div>
  <div class="container">
    <div class="edtech">
      <div class="row">
        <div class="col-lg-7 col-md-8 col-12">
          <h1 class="h1-white title">We are masters in Edtech</h1>
          <p class="body-text-white">
            By combining computer software with educational theory and practice,
            we are revolutionising the way people learn. Our developments in
            Education Technology are opening the door to previously inaccessible
            opportunities, allowing people to re-evaluate their potential.
          </p>
          <p class="body-text-white">
            The digitization of education utilises the analysis of data to
            deliver personalised training that can automatically adapt to each
            student. Tailored lesson plans and immersive content are changing
            the way people view education whilst improving their chance of
            success.
          </p>
          <!-- <button class="btn btn-danger" >Start your tech journey</button> -->
        </div>
      </div>
    </div>
    <div class="edtech-features">
      <div class="feature">
        <img
          src="assets/images/icons/atechy-icons-soft-creation.svg"
          alt="task"
        />
        <p class="body-text-small">
          Real-time analytics and reporting management software
        </p>
      </div>
      <div class="feature">
        <img src="assets/images/icons/atechy-icons-ideo-com.svg" alt="task" />
        <p class="body-text-small">
          Real-time collaboration and video communication
        </p>
      </div>
      <div class="feature">
        <img src="assets/images/icons/atechy-icons-payment.svg" alt="task" />
        <p class="body-text-small">Educational app development</p>
      </div>
      <div class="feature">
        <img src="assets/images/icons/outline.svg" alt="task" />
        <p class="body-text-small">Content creation and management software</p>
      </div>
      <div class="feature">
        <img src="assets/images/icons/atechy-icons-vr-ar.svg" alt="task" />
        <p class="body-text-small">VR/AR enabled education solutions</p>
      </div>
      <div class="feature">
        <img
          src="assets/images/icons/atechy-icons-machine-learning.svg"
          alt="task"
        />
        <p class="body-text-small">Staf/Teacher software</p>
      </div>
      <div class="feature">
        <img src="assets/images/icons/atechy-icons-intel-oc.svg" alt="task" />
        <p class="body-text-small">Remote proctoring</p>
      </div>
    </div>
    <div class="fintech">
      <div class="row">
        <div class="col-lg-7 col-md-8 col-12">
          <div class="passionate-left">
            <h1 class="h1-white title">We are passionate about Fintech</h1>
            <h3 class="title">We can help you</h3>
            <p class="body-text-white">
              Financial technology (Fintech) can transform the way your business
              manages its own finances. At Academo, we develop and utilise
              complex software and algorithms to deliver a streamlined, consumer
              friendly service.
            </p>
            <p class="body-text-white">
              We help businesses by integrating Fintech within their operations.
              Allowing you to take control of your own investments, money
              transfers and bookkeeping can bring you more financial freedom and
              substantial business growth. By simplifying financial processes,
              you can spend less time and energy on paperwork and focus more on
              generating revenue.
            </p>
            <!-- <button class="btn btn-danger" >Build your Fintech now </button> -->
          </div>
        </div>
        <div class="col-lg-5 col-md-4 col-12">
          <div class="passionate-right">
            <img src="assets/images/tech/editor.png" alt="editor" />
          </div>
        </div>
      </div>
    </div>
    <div class="fintect-features">
      <div class="row justify-content-start">
        <div class="col-lg-5 col-md-6">
          <div class="feature">
            <div class="fin-img">
              <img
                src="assets/images/icons/atechy-icons-soft-creation.svg"
                alt="fintech"
              />
            </div>
            <p class="body-text-small">Automating payment lifecycle</p>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="feature">
            <div class="fin-img">
              <img
                src="assets/images/icons/atechy-icons-leverage-blockchain.svg"
                alt="fintech"
              />
            </div>
            <p class="body-text-small">Leverage blockchain technology</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-start">
        <div class="col-lg-5 col-md-6">
          <div class="feature">
            <div class="fin-img">
              <img
                src="assets/images/icons/atechy-icons-nvestment.svg"
                alt="fintech"
              />
            </div>
            <p class="body-text-small">Enhance investment management</p>
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="feature">
            <div class="fin-img">
              <img src="assets/images/icons/customer.svg" alt="fintech" />
            </div>
            <p class="body-text-small">Personalise customer experiences</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
