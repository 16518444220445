<app-navbar></app-navbar>
<app-hero-section></app-hero-section>
<app-stats></app-stats>
<app-mockups></app-mockups>
<app-brand></app-brand>
<app-ai></app-ai>
<app-tech-features></app-tech-features>
<app-medtech></app-medtech>
<app-tech-stacks></app-tech-stacks>
<app-joining></app-joining>
<app-footer></app-footer>
