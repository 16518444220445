import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public isMenuCollapsed = true;
  public headerImage = 'assets/images/header/academo-logo-alt.png';
  public navbarStyle = '';
  public pagesNavbar = '';
  public showBtn = 'hide-btn';
  currentRoute?: string;
  constructor(public router: Router) {}

  ngOnInit(): void {
    if (this.router.url != '/' && this.router.url != '/tech-camp') {
      this.pagesNavbar = 'pages-navbar';
    } else {
      this.pagesNavbar = '';
    }
  }
  scrollToElement(element: any): void {
    var elementTag = document.getElementById(element)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
  scrollToHomeSections($elementId: any): void {
    this.router.events.subscribe((eve: any) => {
      if (eve instanceof NavigationEnd) {
        this.currentRoute = eve.url;
      }
    });
    if (this.currentRoute == '/') {
      this.scrollToElement($elementId);
    } else {
      this.router.navigate(['/']).then(() => {
        setTimeout(() => {
          this.scrollToElement($elementId);
        }, 0);
      });
    }
  }
  scrollToTop(targetedPage: string) {
    this.router.navigate([targetedPage]);
    window.scrollTo(0, 0);
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 200) {
      this.navbarStyle = 'scroll-nav';
      this.pagesNavbar = '';
      this.headerImage = 'assets/images/header/academo-logo-alt.png';
      this.showBtn = 'show-btn';
    } else {
      this.navbarStyle = ``;
      this.pagesNavbar = 'pages-navbar';
      this.headerImage = 'assets/images/header/academo-logo-alt.png';
      this.showBtn = 'hide-btn';
    }
    if (
      (window.pageYOffset <= 200 && this.router.url == '/') ||
      this.router.url == '/tech-camp'
    ) {
      this.pagesNavbar = '';
    }
  }
}
