<app-navbar></app-navbar>
<div class="container">
  <div class="row">
    <div class="col">
      <ul class="list-group my-5">
        <li class="list-group-item">
          <strong>1. POLICY STATEMENT</strong>
          <ul class="list-group">
            <li class="list-group-item">
              1.1 Modern slavery is a crime and a violation of fundamental human
              rights. It takes various forms, such as slavery, servitude, forced
              and compulsory labour and human trafficking, all of which have in
              common the deprivation of a person’s liberty by another in order
              to exploit them for personal or commercial gain. We are committed
              to acting ethically and with integrity in our business dealings
              and relationships and are committed to preventing modern slavery
              in our own business and to helping prevent modern slavery in our
              supply chains.
            </li>
            <li class="list-group-item">
              1.2 We are also committed to ensuring there is transparency in our
              own business and in our approach to tackling modern slavery. Under
              the Modern Slavery Act 2015, we are legally required to disclose
              the steps we take to tackle modern slavery. We expect the same
              high standards from all of our employees and suppliers.
            </li>
            <li class="list-group-item">
              1.3 This policy applies to all persons working for us or on our
              behalf in any capacity, including employees at all levels,
              directors, officers, agency workers, suppliers, seconded workers,
              volunteers, and interns.
            </li>
            <li class="list-group-item">
              1.4 This policy does not form part of any Academo employee’s
              contract of employment and we may amend it at any time.
            </li>
          </ul>
        </li>
        <li class="list-group-item">
          <strong>2. RESPONSIBILITY FOR THIS POLICY</strong>
          <ul class="list-group">
            <li class="list-group-item">
              2.1 The board of directors has overall responsibility for ensuring
              this policy complies with our legal and ethical obligations, and
              that all those under our control comply with it.
            </li>
            <li class="list-group-item">
              2.2 The Legal and Human Resources departments have primary and
              day-to-day responsibility for implementing this policy, monitoring
              its use and effectiveness, dealing with any queries about it, and
              auditing internal procedures.
            </li>
            <li class="list-group-item">
              2.3 Management at all levels are responsible for ensuring those
              reporting to them understand and comply with this policy. Guidance
              on the policy can be obtained from the Legal and Human Resources
              teams.
            </li>
          </ul>
        </li>
        <li class="list-group-item">
          <strong>3. COMPLIANCE WITH THIS POLICY</strong>
          <ul class="list-group">
            <li class="list-group-item">
              3.1 The prevention, detection and reporting of modern slavery in
              any part of our business or supply chains is the responsibility of
              all those working for us or under our control. You are required to
              avoid any activity that might lead to, or suggest, a breach of
              this policy.
            </li>
            <li class="list-group-item">
              3.2 You must notify your manager (or your main point of contact at
              Academo, if you are a supplier) as soon as possible if you believe
              or suspect that a breach of this policy has occurred, or may occur
              in the future.
            </li>
            <li class="list-group-item">
              3.3 You are encouraged to raise concerns about any issue or
              suspicion of modern slavery in any parts of our business or supply
              chains or any supplier tier at the earliest possible stage.
            </li>
            <li class="list-group-item">
              3.4 If you believe or suspect a breach of this policy has occurred
              or that it may occur you must notify your manager (or your main
              point of contact at Academo, if you are a supplier) as soon as
              possible.
            </li>
            <li class="list-group-item">
              3.5 If you are unsure about whether a particular act, the
              treatment of workers more generally, or their working conditions
              within any tier of our supply chain constitute any of the various
              forms of modern slavery, you should raise it with your manager (or
              your main point of contact at Academo, if you are a supplier).
            </li>
            <li class="list-group-item">
              3.6 We aim to encourage openness and will support anyone who
              raises genuine concerns in good faith under this policy, even if
              they turn out to be mistaken. We are committed to ensuring no one
              suffers any detrimental treatment by Academo as a result of
              reporting in good faith their suspicion that modern slavery of
              whatever form is or may be taking place in any part of our own
              business or in any of our supply chains. Detrimental treatment
              includes dismissal, disciplinary action, threats or other
              unfavourable treatment connected with raising a concern. If you
              believe that you have suffered any such treatment, you should
              inform the Chief Human Resources Officer immediately. If the
              matter is not remedied, and you are an employee, you should raise
              it formally using our grievance procedure.
            </li>
          </ul>
        </li>
        <li class="list-group-item">
          <strong>4. COMMUNICATION AND AWARENESS OF THIS POLICY</strong>
          <ul class="list-group">
            <li class="list-group-item">
              4.1 Guidance on this policy forms part of the induction process
              for all individuals who work for us and will be provided otherwise
              as necessary.
            </li>
          </ul>
        </li>
        <li class="list-group-item">
          <strong>5. BREACHES OF THIS POLICY</strong>
          <ul class="list-group">
            <li class="list-group-item">
              5.1 Any employee who breaches this policy will face disciplinary
              action, which could result in dismissal for misconduct or gross
              misconduct.
            </li>
            <li class="list-group-item">
              5.2 We may terminate our relationship with other individuals and
              organisations working for us or on our behalf (including
              suppliers) if they breach this policy.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-footer></app-footer>
