<div class="row registeration">
    <div class="col bg-primary vh-100 d-none d-lg-block">
        <div class="m-4 pl-4" style="height: 60px;">
            <a class="navbar-brand h-100" href="#" style="width: 12rem;">
                <img class="h-100" src="assets/images/logos/academo-logo-alt.png" alt="Academo Logo"/>
            </a>
        </div>
        <div>
            <div class="m-4">
                <carousel>
                    <slide>
                      <img class="w-100" src="assets/images/homepage/johnny-white.svg" alt="" style="max-height: 320px;">
                      <div class="text-center px-4 my-4 text-white ">
                        “We love that Academo will allow us to provide a breadth of hight quality programs and a learning of high quality programs and a learning experience that our employees and interns can drive their own personal development.”
                      </div>
                      <h5 class="text-center text-white">Jonny White</h5>
                      <h6 class="text-center text-white font-weight-light">Quanta Law</h6>
                    </slide>
                    <slide>
                      <img class="w-100" src="assets/images/homepage/johnny-white.svg" alt="" style="max-height: 320px;">
                      <div class="text-center px-4 my-4 text-white">
                        “I am so grateful to this course and Academo. I was a novice to IT, just someone who wanted to switch career paths, and Academo made it possible! The courses were amazing as they covered all areas of the IT discipline of my choice, from basic to advance. I learnt more than what I could have learnt in the university because the programs were designed to ensure high-level participation with practical sessions to confirm what was being taught. It helped me process the learning much quicker, resulting in me passing the course earlier than expected! I really had an astounding experience, and I am certain without doubts that this will help advance my career, opening new and exciting opportunities to my life.”
                      </div>
                      <h5 class="text-center text-white">Muhammad Hamza</h5>
                    </slide>
                    <slide>
                      <img class="w-100" src="assets/images/homepage/johnny-white.svg" alt="" style="max-height: 320px;">
                      <div class="text-center px-4  my-4 text-white">
                        “Having brought James on board, I was marvelled by his work and work ethic, which made me inquire about where he trained. Academo must be doing something special because James is unique compared to his other colleagues. He is so talented, well trained and experienced. James delivers amazing work while staying flexible on other projects, an added advantage to the team. He has further progressed in roles since starting here. Thank you, Academo, for the hard work you guys put in people. Great stuff!”
                      </div>
                      <h5 class="text-center text-white">Rowan.ai</h5>
                    </slide>
                </carousel>  
            </div>
        </div>
    </div>
    <div class="col vh-100" style="overflow-y: scroll; overflow-x: hidden; margin: 0px; padding: 0;">
        <div class="form-header d-flex">
            <button class="btn btn-outline-secondary btn-accent mt-4 mr-4 ml-auto" (click)="goBack()">Back</button>
        </div>
        <div class="form-wrapper">
            <h5 class="form-header text-center mb-4 pb-4" >You are moment away to educate your company</h5>
            <app-enterprise-info-registration-form #enterpriseInfoForm ></app-enterprise-info-registration-form>
            <button  class="btn btn-primary w-100 p-3 mt-4" (click)="next()">Confirm and Submit</button>
        </div>
    </div>
</div>
