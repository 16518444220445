<form [formGroup]="formGroup" [ngClass]="{'submitted': isSubmitted}">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-label">Atechy Ref.</label>
        <input type="text" class="form-control" formControlName="ref" placeholder="your reference number">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-label">First Name</label>
        <input type="text" class="form-control" formControlName="firstName" placeholder="enter your first name">
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-label">Last Name</label>
        <input type="text" class="form-control" formControlName="lastName" placeholder="enter your last name">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="form-group">
        <label class="form-label">Date of birth</label>
        <app-date-input formControlName="dateOfBirth"></app-date-input>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="form-label">Gender</label>
        <div class="form-input-group mt-2">
          <div class="form-check form-check-inline">
            <input id="maleRadio" type="radio" class="form-check-input" formControlName="gender" name="gender"
              value="male">
            <label class="form-check-label" for="maleRadio">male</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="femaleRadio" type="radio" class="form-check-input" formControlName="gender" name="gender"
              value="female">
            <label class="form-check-label" for="femaleRadio">female</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label">Email</label>
        <input type="email" class="form-control" formControlName="email" placeholder="enter email address">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label">UAE Mobile Number</label>
        <input type="text" class="form-control" formControlName="mobile" placeholder="+971 55 555 5555">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="form-group">
        <label class="form-label">Mother's Name</label>
        <input type="text" class="form-control" formControlName="motherName"
          placeholder="please enter mother name as in your passport">
      </div>
    </div>
  </div>
  <legend class="mt-4">UAE Address</legend>
  <div formGroupName="address">
    <div class="row mb-2">
      <div class="col">
        <input type="text" class="form-control mb-2" formControlName="addressLine1" placeholder="Address line 1">
        <input type="text" class="form-control" formControlName="addressLine2" placeholder="Address line 2">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <input type="text" class="form-control" formControlName="city" placeholder="City / Town">
      </div>
      <div class="col-6">
        <input type="text" class="form-control" formControlName="state" placeholder="State / Province / Emirate">
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <input type="text" class="form-control" formControlName="postalCode" placeholder="Zip / Postal Code">
      </div>
    </div>
  </div>
  <legend class="mt-4">Home Country Address</legend>
  <div formGroupName="homeAddress">
    <div class="row mb-2">
      <div class="col">
        <input type="text" class="form-control mb-2" formControlName="addressLine1" placeholder="Address line 1">
        <input type="text" class="form-control" formControlName="addressLine2" placeholder="Address line 2">
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-6">
        <input type="text" class="form-control" formControlName="city" placeholder="City / Town">
      </div>
      <div class="col-6">
        <input type="text" class="form-control" formControlName="state" placeholder="State / Province / Emirate">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12 mb-2">
        <input type="text" class="form-control" formControlName="postalCode" placeholder="Zip / Postal Code">
      </div>
      <div class="col-md-6 col-sm-12">
        <ng-select class="custom" [items]="countries" bindLabel="name" bindValue="name"
          placeholder="please select your home country" formControlName="country"></ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label class="form-label">Home Country Phone Number</label>
        <input type="text" class="form-control" formControlName="homeCountryPhone" placeholder="+1 55 555 5555">
      </div>
    </div>
  </div>
  <legend>UAE Emergency Contact</legend>
  <div formGroupName="emergencyContact">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="First Name" formControlName="firstName">
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Relation" formControlName="relation">
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="+971 55 555 5555" formControlName="phone">
        </div>
      </div>
    </div>
  </div>
</form>
