import { Component, forwardRef } from '@angular/core';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUpload } from '../models/file-upload.model';
import { FileUploadService } from '../services/file-upload.service';
import { faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';

export interface fileUploaderValue {
    url?: string;
    path?: string;
    fileName?: string;
}

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FileUploaderComponent),
        multi: true
    }]
})
export class FileUploaderComponent implements ControlValueAccessor {
    // Fontawesome Icons
    faTrash = faTrash;
    faCheck = faCheck;

    private _onChanged: (value: any) => void = (_) => {};
    private _onTouched: (value: any) => void = (_) => {};

    selectedFile: File | null = null;
    isUploading = false;
    progress: number = 0;
    value?: fileUploaderValue;
    private mFileUpload: FileUpload | null = null;
    set fileUpload(value: FileUpload | null) {
        this.mFileUpload = value;
        if (value) {
            this.value = {
                url: value?.url,
                path: value?.path,
                fileName: value?.name
            }
        } else {
            this.value = undefined;
        }
        this._onChanged(this.value);
        this._onTouched(this.value);
    }
    get fileUpload(): FileUpload | null {
        return this.mFileUpload;
    }
    private mUploadTask: AngularFireUploadTask | null = null;
    set uploadTask(value: AngularFireUploadTask | null) {
        this.mUploadTask = value
        if (this.mUploadTask != null) {
            this.mUploadTask.catch((err: Error) => {
                console.log(err)
                this.isUploading = false;
            });
            this.mUploadTask.percentageChanges().subscribe({
                next: (progress: number | undefined) => {
                    if (progress) {
                        this.progress = progress
                    }
                }
            });
        }
    }
    get uploadTask() : AngularFireUploadTask | null {
        return this.mUploadTask;
    }

    constructor(private fileUploadService: FileUploadService) {}

    selectFile(event: any): void {
        this.selectedFile = (event.target as any).files[0]
        this.upload();
    }
    upload() {
        if (this.selectedFile != null) {
            this.isUploading = true;
            this.uploadTask = this.fileUploadService.pushFileToStorage(new FileUpload(this.selectedFile), this.onUploadComplete)
        }
    }
    onUploadComplete = (result: FileUpload) => {
        this.isUploading = false;
        if (result) {
            this.fileUpload = result;
        }
    }
    cancelUpload() {
        this.selectedFile = null;
        this.isUploading = false;
        if (this.uploadTask) {
            this.uploadTask.cancel();
            this.uploadTask = null;
        }
        this.fileUpload = null;
    }

    clearFile() {
        this.fileUpload = null;
        this.isUploading = false;
        this.uploadTask = null;
        this.progress = 0;
        this.selectedFile = null;
    }

    writeValue(obj: any): void {
        this.value = obj;
        this._onChanged(this.value);
        
    }
    registerOnChange(fn: any): void {
        this._onChanged = fn;
    }
    registerOnTouched(fn: any): void {
        this._onTouched = fn
    }
}
