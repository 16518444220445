<div class="container-fluid">
  <div class="row">
    <div class="col pl-0 d-lg-block d-none no-scroll">
      <div
        class="bg-gradient h-100 d-flex align-items-center justify-content-center"
      >
        <div
          class="p-4"
          style="position: absolute; top: 0; left: 0; cursor: pointer"
        >
          <img
            src="assets/images/header/academo-logo-alt.png"
            alt="logo"
            class="w-50"
            [routerLink]="['/']"
          />
        </div>
        <div
          class="d-flex justify-content-center flex-column align-items-start my-5 mx-4"
        >
          <div
            class="get-started-box mb-4 px-3 px-md-5 d-flex align-items-start"
          >
            <img
              src="assets/images/get-started/tick-circle.svg"
              alt="icon"
              class="mr-3"
            />
            <div class="right-box">
              <h3 class="font-weight-semi-bold font-color-white mb-3">
                Get started instantly:
              </h3>
              <p class="font-color-white">
                Our tech specialists will contact you and provide a bespoke
                solution tailored to your business needs.
              </p>
            </div>
          </div>
          <div
            class="get-started-box mb-4 px-3 px-md-5 d-flex align-items-start"
          >
            <img
              src="assets/images/get-started/tick-circle.svg"
              alt="icon"
              class="mr-3"
            />
            <div class="right-box">
              <h3 class="font-weight-semi-bold font-color-white mb-3">
                Exceptional Support:
              </h3>
              <p class="font-color-white">
                Get access to our global network of outstanding engineers and
                designers under a single shared platform.
              </p>
            </div>
          </div>
          <div class="get-started-box px-3 px-md-5 d-flex align-items-start">
            <img
              src="assets/images/get-started/tick-circle.svg"
              alt="icon"
              class="mr-3"
            />
            <div class="right-box">
              <h3 class="font-weight-semi-bold font-color-white mb-3">
                Connect with hundreds of businesses:
              </h3>
              <p class="font-color-white">
                Be a part of a growing, fast-paced industry. Become one of our
                worldwide partners, and let’s together think outside the box.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col vh-lg-100 overflow-y-scroll">
      <ng-container *ngIf="!isRequestSubmitted">
        <div class="row d-md-none d-sm-block">
          <div class="col p-0">
            <div class="bg-gradient p-4">
              <img
                src="assets/images/header/academo-logo-alt.png"
                alt="logo"
                class="w-75"
                [routerLink]="['/']"
              />
            </div>
          </div>
        </div>
        <div class="row pt-4 d-flex justify-content-center">
          <div class="col-md-10 col-lg-9 col-sm-12">
            <h1 class="text-center my-5 pb-5">
              Let us build for your business
            </h1>
            <form
              [formGroup]="formGroup"
              [ngClass]="{ submitted: isSubmitted }"
            >
              <div class="form-group">
                <label>Company name</label>
                <input
                  type="text"
                  class="form-control"
                  name="companyName"
                  formControlName="companyName"
                />
              </div>
              <div class="form-group">
                <label>Email address</label>
                <input
                  type="text"
                  class="form-control"
                  name="email"
                  formControlName="email"
                />
              </div>
              <div class="form-group">
                <label class="form-label">Country</label>
                <ng-select
                  class="custom"
                  [items]="en_short_name"
                  bindLabel="en_short_name"
                  bindValue="en_short_name"
                  placeholder=""
                  formControlName="country"
                ></ng-select>
              </div>
              <div class="form-group">
                <label>Project brief (keep it brief and simple)</label>
                <textarea
                  class="form-control"
                  rows="5"
                  name="brief"
                  formControlName="brief"
                ></textarea>
              </div>
              <div class="form-group text-center mb-4 pb-4">
                <button
                  class="btn btn-dark"
                  (click)="sendRequestButton($event)"
                >
                  Send Request
                </button>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="text-danger text-center"
                    *ngIf="isSubmitted && formGroup.invalid"
                  >
                    some fields are missing, please check above.
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isRequestSubmitted">
        <div class="row d-md-none d-sm-block">
          <div class="col p-0">
            <div class="bg-gradient p-4">
              <img
                src="assets/images/header/logo.svg"
                alt="logo"
                [routerLink]="['/']"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10 col-sm-12">
            <div class="mt-5">
              <img
                class="w-100 mb-2 mt-5"
                src="assets/images/get-started/awesome.svg"
                alt="welcome"
              />
              <h1 class="font-weight-semi-bold text-center Awesome-text">
                Awesome
              </h1>
            </div>
            <h2 class="text-center">We have received your request!</h2>
            <h4 class="text-center py-5">We will be in touch soon.</h4>
            <div class="text-center mb-5 pb-5">
              <a class="btn btn-dark" [routerLink]="['/']">Back to home</a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
