import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EnterpriseRegistrationComponent } from "./forms/enterprise-registration.component";
import { StudentRegistrationComponent } from "./forms/student-registration.component";

const routes: Routes = [{
    path: 'student-registration', component: StudentRegistrationComponent
},
{
    path: 'enterprise-registration', component: EnterpriseRegistrationComponent
}]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule
    ]
})
export class RegistrationRoutingModule {}